import type { AutocompletePrediction } from '$types/autocomplete.types';
import type { Coordinates } from '$types/booking.types';
import type { Bounds } from '$types/map.types';

export const PALMA_AIRPORT_COORDINATES: Coordinates = {
	lat: 39.55143473227957,
	lng: 2.7367762364000403
};

export const PALMA_LOCATION_RESTRICTION: Bounds = {
	south: 38.67451686079959,
	west: 1.746264894702654,
	north: 40.528352603759544,
	east: 3.772875780974263
};

export const PALMA_AIRPORT_PLACE_ID = 'ChIJ8zXb9S-UlxIRVvm3aWXaMXA';

export const defaultAutoCompleteResults: AutocompletePrediction[] = [
	{
		place_id: 'ChIJ8zXb9S-UlxIRVvm3aWXaMXA',
		structured_formatting: {
			main_text: 'Palma de Mallorca Airport (PMI)',
			secondary_text: 'Palma, Spain'
		},
		type: 'airport'
	},
	{
		place_id: 'ChIJa-TUn5GVlxIRtPB6JYWSM4o',
		structured_formatting: {
			main_text: 'Hard Rock Cafe Mallorca Airport Rock Shop (Terminal C)',
			secondary_text: 'Palma, Spain'
		},
		type: 'store'
	},
	{
		place_id: 'ChIJKSPO88yJlxIREEJXf3GvAgo',
		structured_formatting: {
			main_text: 'Santa Ponsa',
			secondary_text: 'Spain'
		},
		type: 'point_of_interest'
	},
	{
		place_id: 'ChIJw5BiM7GOlxIR1IQEVGuJNq8',
		structured_formatting: {
			main_text: 'Son Caliu Beach',
			secondary_text: 'Son Caliu, Spain'
		},
		type: 'beach'
	},
	{
		place_id: 'ChIJkVCbC1SSlxIRR8HZISyPnGU',
		structured_formatting: {
			main_text: 'UR Palacio Avenida',
			secondary_text: 'Carrer del Marquès de la Fontsanta, Palma, Spain'
		},
		type: 'hotel'
	}
];
